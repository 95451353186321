import { graphql } from "gatsby"
import React from "react"
import JobsContainer from "../components/Jobs/JobsContainer"
import JobsHeader from "../components/Jobs/JobsHeader"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { IJobOffer, ILogo } from "../libs/interfaces"

function Jobs({ data }: any) {
  const { title, subtitle, header_img } = data.static.frontmatter

  const offers: IJobOffer[] = [
    ...data.offers.nodes.map((node: any) => ({
      id: node.id,
      html: node.html,
      ...node.frontmatter,
      logo_dark: data.logos.nodes.find(
        (el: ILogo) => el.name == node.frontmatter.logo_dark
      ),
      logo_light: data.logos.nodes.find(
        (el: ILogo) => el.name == node.frontmatter.logo_light
      ),
    })),
  ]

  const header_img_data = data.staticImg.nodes.find((el: any) => {
    return header_img == el.name
  })?.childrenImageSharp[0]

  const seo = {
    metaTitle: title,
    metaDescription: subtitle,
  }

  return (
    <Layout>
      <Seo seo={seo} lang={data.locales.edges[0].node.language} />
      <JobsHeader
        title={title}
        subtitle={subtitle}
        header_img={header_img_data}
      />
      <JobsContainer jobOffers={offers} />
    </Layout>
  )
}

export default Jobs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    static: markdownRemark(
      frontmatter: { slug: { eq: "jobs/static" }, lang: { eq: $language } }
    ) {
      frontmatter {
        title
        subtitle
        header_img
      }
    }
    staticImg: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)/" }
        relativePath: { glob: "assets/jobs/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
      }
    }
    offers: allMarkdownRemark(
      filter: {
        frontmatter: {
          slug: { glob: "jobs/offers/*" }
          lang: { eq: $language }
        }
      }
    ) {
      nodes {
        html
        id
        frontmatter {
          slug
          company
          name
          location
          type
          date
          salary_period
          salary
          logo_light
          logo_dark
        }
      }
    }
    logos: allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(tif)|(tiff)|(webp)|(jpeg)|(svg)/" }
        relativePath: { glob: "assets/logos/*" }
      }
    ) {
      nodes {
        name
        childrenImageSharp {
          gatsbyImageData
        }
        relativePath
        extension
      }
    }
  }
`
