import React from "react"
import { IJobOffer } from "../../libs/interfaces"

import JobCard from "./JobCard"

function JobsContainer({ jobOffers }: { jobOffers: IJobOffer[] }) {
  return (
    <div className="container">
      <div className="my-8 grid grid-cols-1 gap-[30px] md:grid-cols-2 lg:grid-cols-3">
        {jobOffers.map((offer: IJobOffer) => {
          return <JobCard key={offer.id} jobOffer={offer}></JobCard>
        })}
      </div>
    </div>
  )
}

export default JobsContainer
