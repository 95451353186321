import React from "react"
import { displayLogo } from "../../libs/displayLogo"
import { IJobOffer } from "../../libs/interfaces"

function JobCard({ jobOffer }: { jobOffer: IJobOffer }) {
  return (
    <div className="rounded-md shadow dark:shadow-gray-700">
      <div className="p-6">
        <a
          href={`/${jobOffer.slug}`}
          className="title h5 text-lg font-semibold hover:text-primary-600"
          aria-label="Offer Link"
        >
          {jobOffer.name}
        </a>
        <p className="mt-2 flex gap-2 text-slate-500 dark:text-slate-300">
          <i className="mdi mdi-clock text-primary-600"></i>
          <span>{jobOffer.date}</span>
        </p>

        <div className="mt-4 flex items-center justify-between">
          <span className="h-5 rounded bg-primary-600/5 px-2.5 py-0.5 text-xs font-bold text-primary-600">
            {jobOffer.type}
          </span>

          <p className="text-slate-500 dark:text-slate-300">
            <i className="mdi mdi-usd-circle text-primary-600"></i>€
            {jobOffer.salary}/{jobOffer.salary_period}
          </p>
        </div>
      </div>
    </div>
  )
}

export default JobCard
